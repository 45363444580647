<template>
  <div class="wheel-wrapper" @click="emitWheelClick">
    <div class="wheel">
      <img class="outer-wheel" src="../assets/outer-ring.png">
      <img class="pointer" src="../assets/pointer.png">
      <img class="inner-wheel" src="../assets/wheel-1.png">
      <img class="most-inner-wheel" :src="this.pageWheelCenterImage || require('@/assets/inner-circle.png')">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Wheel',
  props: ['pageData'],
  data() {
    return {
      pageWheelCenterImage: null,
    };
  },
  mounted() {
    this.pageWheelCenterImage = this.pageData.pageWheelCenterImage;
  },
  methods: {
    emitWheelClick() {
      this.$emit('wheel-spun', true);
    },
  },
};
</script>

<style lang="scss">
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3600deg);
    }
  }
  @keyframes spin2 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3563deg);
    }
  }
  @keyframes spin3 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3636deg);
    }
  }
  @keyframes spin4 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3672deg);
    }
  }
  @keyframes spin5 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(3743deg);
    }
  }
  .wheel-wrapper {
    padding: 1.25rem 0;
    overflow: hidden;
    cursor: pointer;
    &.spinning-1 {
      .inner-wheel {
        animation-name: spin;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-2 {
      .inner-wheel {
        animation-name: spin2;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-3 {
      .inner-wheel {
        animation-name: spin3;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-4 {
      .inner-wheel {
        animation-name: spin4;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
    &.spinning-5 {
      .inner-wheel {
        animation-name: spin5;
        animation-duration: 5s; /* or: Xms */
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.7,.15,.44,1.33);
        animation-fill-mode: forwards; /* or: backwards, both, none */
      }
    }
  }
  .wheel {
    width: 600px;
    height: 600px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
  }
  .most-inner-wheel {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
  }
  .outer-wheel {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .inner-wheel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 95%;
  }
  .pointer {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 45px;
  }
  @media (max-width: 1215px) {
    .wheel {
      width: 500px;
      height: 500px;
    }
    .most-inner-wheel {
      width: 182px;
    }
    .pointer {
      width: 38px;
    }
  }
  @media (max-width: 1023px) {
    .most-inner-wheel {
      width: 150px;
    }
  }
  @media (max-width: 450px) {
    .most-inner-wheel {
      width: 125px;
    }
    .pointer {
      width: 32px;
    }
  }
</style>
